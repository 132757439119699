@import url("https://fonts.googleapis.com/css2?family=Kufam:ital,wght@0,400..900;1,400..900&display=swap");

* {
  font-family: "kufam", sans-serif;
}

  

body {
  width: auto;
  background-color: #3B1729;
}

.hamda {
  display: flex !important;
  margin: 0;
  padding: 30px;
  background-image: url(./images/loginBg.jpg);
  backdrop-filter: blur(2px);
  background-size: cover;
  background-position: center;
  background-color: #e8e8e8;
  min-height: 100vh;

  justify-content: center;
  align-items: center;
}
.app1 {
  background-color: white;

  display: flex;
  margin: 0 !important;
  padding: 10px !important;
  height: 528px;
  border-radius: 10px;
  width: 1000px !important;
}

.app1 img {
  border-radius: 8px;
}
.footImage {
  background-color: #3B1729;
  bottom: 30;
  left: 0;
  right: 0;
  padding: 12px;
}
.footImage img {
  width: 80px;
  padding-left: 30px;
}

.footerTitle {
  color: #f8e46f;
}

.left img {
  object-fit: cover;
  height: 100%;
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.commButton {
  margin-bottom: 120px;
}
.buttonFont {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}
.buttonTitle {
  width: 222px;
  height: 76px;
  background-color: #951d5a !important;
  border-radius: 10px;
}
.buttonTitle2 {
  width: 450px !important;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2;
  background-color: #951d5a !important;
  border-radius: 32px;
}
.buttonTitle:hover {
  background-color: #948af4 !important;
}
.buttonTitle2:hover {
  background-color: #948af4 !important;
}
.buttonTitle1 {
  text-align: left;
}
.IconsWrap {
  flex-wrap: wrap;
  font-size: 12px;
}
.ContentWrap {
  flex-wrap: wrap;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 1px;
}

.image-grid div {
  padding: 25px; /* Padding for each cell */
  display: grid;
  justify-items: center; /* Horizontally center the content within the cell */
  align-items: center;
}

.image-grid img {
  max-width: 100%;
  max-height: 100%;
}
.logButton {
  background-color: #f8e46f;
  padding: 10px;
}
.logButton:hover {
  background-color: #948af4;
  color: #ffffff !important;
}

.commBox {
  background-color: black;
}
/* .commPadding {
  display: flex
  ;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 15rem;
  } */

.Payment {
  width: 100%;
  height: 1000px;
  background: transparent;
}

/* @media screen and (max-width: 600px) {
  /* .loginPage {
    display: none;
  } */
  /* .commPadding {
    display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;
       
    }
} */ 
 /* Ensuring all buttons have the same width */
button.btn-block {
  width:100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-decoration: none;
}
button.btn-block2 {
  width:100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* Adjusting the title font size for responsiveness */
.titleCommunication {
  font-size: 2.5rem; /* Default size for large screens */
}

@media (max-width: 992px) {
  .titleCommunication {
    font-size: 2rem !important; /* For medium screens */
  }
  .buttonTitle2 {
    width: 350px !important;
    height: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2;
    background-color: #951d5a !important;
    border-radius: 32px;
  }

}

@media (max-width: 768px) {
  .titleCommunication {
    font-size: 2.75rem !important; /* For small screens */
  }
  .buttonTitle2 {
    width: 350px !important;
    height: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2;
    background-color: #951d5a !important;
    border-radius: 32px;
  }
  button.btn-block2 {
    width:100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: left;
    text-decoration: none;
  }
  

}

@media (max-width: 576px) {
  .titleCommunication {
    font-size: 2rem !important; /* For extra small screens */
  }
  .buttonTitle2 {
    width: 300px !important;
    height: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2;
    background-color: #951d5a !important;
    border-radius: 32px;
  }

}
